<div id="general" class="iw-container">
    <div [style.background-image]="'url(assets/images/fondoTarjetas.webp'" class="static-component">
        <!-- <div class="general">-->
            <div class="general-1">
                <h3>¿Y cuál es exactamente la diferencia entre <br>los seguros tradicionales de automóvil e Ituran?</h3>
            </div>
            <div class="general-2">
                <div class="col-l">
                    <div class="first-level">
                        <h5>SEGUROS TRADICIONALES</h5>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>El precio es ELEVADO</p>
                            <p>Con una alta suma inicial o precio anual alto.</p>
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>El precio es VARIABLE</p>
                            <p>Depende del modelo, año y residencia del usuario.</p>
                        </div>
                    </div>
                    <div class="grey-logo">
                        <img src="assets/images/logo_ituran_gris.png" alt="">
                    </div>
                </div>
                <div class="col-r">
                    <div class="first-level">
                        <div class="logo-container">
                            <img src="assets/ituran/ituran.svg" alt="">
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>Sin COTIZACIONES</p>
                            <p>El precio es único para todos los modelos y año del automóvil.</p>
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>Sin plazos FORZOSOS</p>
                            <p>Contrata el servicio únicamente el tiempo que tú decidas.</p>
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>Sólo paga lo que NECESITAS</p>
                            <p>Sin coberturas innecesarias.</p>
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>Acceso a una App </p>
                            <p>Donde podrás localizar tu auto vía GPS en tiempo real.</p>
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>Pagos mensuales</p>
                            <p>Domiciliados a tu tarjeta o pago en OXXO.</p>
                        </div>
                    </div>
                    <div class="compare">
                        <div class="bullet"></div>
                        <div class="compare-text">
                            <p>Contamos con el mejor sistema de geolocalización.</p>
                            <p>En caso de robo total, recuperamos tu vehículo en 48 horas*.</p>
                        </div>
                    </div>
                </div>
            </div>
        <!-- </div> -->
        <!-- <img src="assets/images/general-bg.webp"> -->
    </div>
</div>
