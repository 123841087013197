// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  sepomexToken: '3d3475bb-9071-4093-bdab-f10c325be137',
  ituranApiBaseUrl: 'https://partnergtw-training.ituran.com.mx/api/v3/',
  a: 'teste.producao',
  b: 'SUPERUSUARIO280190',
  url_pago: 'https://pg.ituran.com.mx/ITSPaymentGateway/api/',
  options: 'application/json',
  c: 'its.ecomerce',
  d: '@ssur4nc3.api.2020',
  url_catalogsAPI: 'https://assurance.ituran.com.mx/CatalogsAPI/api/',
  cotizacionesIds: [
    {
      nombre: '',
      id: 1
    },
    {
      nombre: '',
      id: 1
    },
    {
      nombre: '',
      id: 1
    },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
