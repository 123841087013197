<div id="contaianer" class="iw-container" (mousemove)="mouseMove($event)">
    <div class="banner">
        <div class="img-container">
            <img class="" src="../../../../assets/images/welcome-slide-1.webp">
        </div>

        <div class="lead-container">
            <app-lead></app-lead>
        </div>
    </div>
</div>
<!-- <div id="contaianer">
    <section id="wrapper">
        <div class="p1" data-speed="0.01" data-revert="true"></div>
        <div class="p2" data-speed="0.02"></div>
        <div class="p3" data-speed="0.01"></div>
        <div class="letra" data-speed="0">vertigo</div>
    </section>
</div> -->



