<div class="iw-container">
    <div class="header">
        <!-- <div class="col-l"> -->
            <div class="logo-container" (click)='goHome()'>
                <img src="assets/ituran/ituran.svg" alt="">
            </div>
            <div class="message-container">
                <p>Si necesitas más información comunícate ahora mismo con un asesor.</p>
            </div>

            <div class="buttons-container">
                <a href="tel:8009119898">
                    <button class="btn-cc"><img src="assets/icons/callIcon.png"> <p>800 911 9898</p></button>
                </a>
                <div class="btn-separator"></div>
                <a href="https://wa.me/+525523046609" target="_blank">
                    <button class="btn-wa"><img src="assets/icons/WhatsAppIcon.png"><p>WhatsApp</p></button>
                </a>
            </div>
            <div class="cc-icon-container">
                <img src="assets/icons/callCenter.png" alt="">
            </div>
    </div>
</div>