import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-what-next',
  templateUrl: './what-next.component.html',
  styleUrls: ['./what-next.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom 
})
export class WhatNextComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
