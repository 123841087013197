<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" type="text/css" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
<div class="bg" [style.background-image]="'url(assets/images/flujo-bg.png'">
    <div style="padding-top: 150px;"></div>
    <app-progress-bar id="progressBar" [currentStep]='_userDataService.step'></app-progress-bar>
    <div class="container">
        <form [formGroup]="stepForm" class="">
            <div class="step-1">
                <div class="form-title">
                    <h4>DATOS DE FACTURACIÓN</h4>
                </div>
                <div class="use-saved">
                    <button  (click)="toggleUseSavedData()">{{useSavedData ? 'No usar' :'Usar'}} los mismos datos</button>
                </div>
            </div>
            <div class="row"> 
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Nombre(s)*" 
                            formControlName="nombre"
                            maxlength = 18
                            (change)="RFCModificado()"
                            [ngClass]="{'is-invalid' : (stepForm.get('nombre').touched || stepForm.get('nombre').dirty) && !stepForm.get('nombre').valid }"
                            onkeypress="return isLetterKey(event)" onpaste="return isLetterKey(event)"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Apellido paterno" 
                            formControlName="paterno"
                            maxlength = 18
                            (change)="RFCModificado()"
                            [ngClass]="{'is-invalid' : (stepForm.get('paterno').touched || stepForm.get('paterno').dirty) && !stepForm.get('paterno').valid }"
                            onkeypress="return isLetterKey(event)" onpaste="return isLetterKey(event)"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Apellido materno" 
                            formControlName="materno"
                            maxlength = 18
                            (change)="RFCModificado()"
                            [ngClass]="{'is-invalid' : (stepForm.get('materno').touched || stepForm.get('materno').dirty) && !stepForm.get('materno').valid }"
                            onkeypress="return isLetterKey(event)" onpaste="return isLetterKey(event)"
                            >
                </div>
                <div class="form-control  col-md-6 col-sm-12">
                    <input  type="text" placeholder="Teléfono" 
                            formControlName="telefono"
                            maxlength=10
                            digitOnly
                            [ngClass]="{'is-invalid' : (stepForm.get('telefono').touched || stepForm.get('telefono').dirty) && !stepForm.get('telefono').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Email" 
                            formControlName="correo"
                            [ngClass]="{'is-invalid' : (stepForm.get('correo').touched || stepForm.get('correo').dirty) && !stepForm.get('correo').valid }"
                            maxlength = 55
                            >
                </div>
                <div class="form-control date-control col-md-6 col-sm-12">
                    <label>Fecha de nacimiento</label>
                    <input  type="date" placeholder="" 
                            formControlName="nacimiento"
                            (change)="RFCModificado()"
                            [ngClass]="{'is-invalid' : (stepForm.get('nacimiento').touched || stepForm.get('nacimiento').dirty) && !stepForm.get('nacimiento').valid }"
                            >
                    <i class="fa fa-calendar"></i>
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="RFC" 
                            formControlName="rfc"
                            disabled
                            [ngClass]="{'is-invalid' : (stepForm.get('rfc').touched || stepForm.get('rfc').dirty) && !stepForm.get('rfc').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Homoclave" 
                            formControlName="clave"
                            style="text-transform: uppercase;"
                            maxlength="3"
                            [ngClass]="{'is-invalid' : (stepForm.get('clave').touched || stepForm.get('clave').dirty) && !stepForm.get('clave').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="CP" 
                            digitOnly
                            (keyup)="searchCP()"
                            formControlName="cp"
                            [ngClass]="{'is-invalid' : (stepForm.get('cp').touched || stepForm.get('cp').dirty) && !stepForm.get('cp').valid }"
                            >
                    <!-- <button (click)="searchCP()">
                                Comprobar CP
                    </button> -->
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Estado" 
                            formControlName="estado"
                            [ngClass]="{'is-invalid' : (stepForm.get('estado').touched || stepForm.get('estado').dirty) && !stepForm.get('estado').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Ciudad" 
                            formControlName="ciudad"
                            [ngClass]="{'is-invalid' : (stepForm.get('ciudad').touched || stepForm.get('ciudad').dirty) && !stepForm.get('ciudad').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Municipio" 
                            formControlName="municipio"
                            [ngClass]="{'is-invalid' : (stepForm.get('municipio').touched || stepForm.get('municipio').dirty) && !stepForm.get('municipio').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <!-- <input  type="text" placeholder="Colonia" 
                            formControlName="colonia"
                            [ngClass]="{'is-invalid' : (stepForm.get('colonia').touched || stepForm.get('colonia').dirty) && !stepForm.get('colonia').valid }"
                            > -->
                    <select formControlName="colonia" [ngClass]="{'is-invalid' : (stepForm.get('colonia').touched || stepForm.get('colonia').dirty) && !stepForm.get('colonia').valid }">
                        <option value="0" disabled [ngValue]="null">Selecciona Colonia</option>
                        <option value="{{col.response.asentamiento}}" *ngFor="let col of _userDataService.userDataModel.sepomexResponse">
                            {{col.response.asentamiento}}
                        </option>
                    </select>
                    <i class="fa fa-chevron-down select-i"></i>
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Calle" 
                            formControlName="calle"
                            [ngClass]="{'is-invalid' : (stepForm.get('calle').touched || stepForm.get('calle').dirty) && !stepForm.get('calle').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="No." 
                            formControlName="exterior"
                            digitOnly
                            [ngClass]="{'is-invalid' : (stepForm.get('exterior').touched || stepForm.get('exterior').dirty) && !stepForm.get('exterior').valid }"
                            >
                </div>
                <div class="form-control col-md-6 col-sm-12">
                    <input  type="text" placeholder="Indicaciones" 
                            formControlName="indicaciones"
                            [ngClass]="{'is-invalid' : (stepForm.get('indicaciones').touched || stepForm.get('indicaciones').dirty) && !stepForm.get('indicaciones').valid }"
                            >
                </div>

                <div class="form-control col-md-6 col-sm-12">
                    <select formControlName="regimenFiscal" [ngClass]="{'is-invalid' : (stepForm.get('regimenFiscal').touched || stepForm.get('regimenFiscal').dirty) && !stepForm.get('regimenFiscal').valid }"
                    (change)="onChange($event.target.value)"
                    >
                        <option value="0" disabled [ngValue]="null">Selecciona Regimen Fiscal</option>
                        <option value="{{col.codigo}}" *ngFor="let col of listaRegimenesFiscales">
                            {{col.descripcion}}
                        </option>
                    </select>
                    <i class="fa fa-chevron-down select-i"></i>
                </div>

                <div class="form-control col-md-6 col-sm-12">
                    <select formControlName="usoCfdi" [ngClass]="{'is-invalid' : (stepForm.get('usoCfdi').touched || stepForm.get('usoCfdi').dirty) && !stepForm.get('usoCfdi').valid }">
                        <option value="0" disabled [ngValue]="null">Selecciona Uso de CFDI</option>
                        <option value="{{col.codigo}}" *ngFor="let col of listaUsosCfds">
                            {{col.descripcion}}
                        </option>
                    </select>
                    <i class="fa fa-chevron-down select-i"></i>
                </div>

            </div>
            <!-- <h3 style="text-align: center;">IMPORTE</h3>
            <div *ngIf="importesLoaded" class="step-3"> 
                <div *ngIf="importes.includes('vehiculoParticular')" class="form-control checkbox-control checkbox-card">
                    <label for="vehiculoParticular">Mi vehículo es de uso particular sin fines de lucro</label>
                    <input  id="vehiculoParticular" type="checkbox" 
                            formControlName="vehiculoParticular"
                            [ngClass]="{'is-invalid' : (stepForm.get('vehiculoParticular').touched || stepForm.get('vehiculoParticular').dirty) && !stepForm.get('vehiculoParticular').valid }"
                            >
                </div>
                <div class="form-control checkbox-control checkbox-card">
                    <label for="vehiculoSinSiniestro">Mi vehículo NO tiene ningún siniestro por reclamar</label>
                    <input  id="vehiculoSinSiniestro" type="checkbox" 
                            formControlName="vehiculoSinSiniestro"
                            [ngClass]="{'is-invalid' : (stepForm.get('vehiculoSinSiniestro').touched || stepForm.get('vehiculoSinSiniestro').dirty) && !stepForm.get('vehiculoSinSiniestro').valid }"
                            >
                </div>
                <div class="form-control checkbox-control checkbox-card">
                    <label for="terminosCondiciones">Términos y condiciones</label>
                    <input  id="terminosCondiciones" type="checkbox" 
                            formControlName="terminosCondiciones"
                            [ngClass]="{'is-invalid' : (stepForm.get('terminosCondiciones').touched || stepForm.get('terminosCondiciones').dirty) && !stepForm.get('terminosCondiciones').valid }"
                            >
                </div>
            </div> -->
            <div class="navigation"> 
                <button (click)='prev()'>Anterior</button>
                <button [disabled]="!stepForm.valid" (click)='save()' id="btn-sig-factura">Siguiente</button>
            </div>
        </form>
        
    </div>
</div>

