<div class="iw-container">
    <div [style.background-image]="'url(assets/images/general-bg.webp'" class="static-component col-12">
        <div class="general-1 row">
            <h4>¿Porqué Ituran es la alternativa ideal para ti?</h4>
            <p>Ituran es un servicio de <span>localización, recuperación, robo total de automóvil* y daños a terceros,<br></span> a un precio accesible y sin plazos forzosos.</p>
        </div>
        <div class="cards row">
            <!-- <div *ngFor="let card of planCards" class="card-container">
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="plan-logo">
                                <img src="{{card.imgUrl}}">
                            </div>
                            <p [innerHTML]="card.textFront" class="plan-desc"></p>
                            <div class="plan-tags">
                                <div *ngFor="let tag of card.serviceTags" class="plan-tag">
                                    <img src="{{tag.imgUrl}}" alt="">
                                    <p [innerHTML]="tag.text"></p>
                                </div>
                            </div>
                        </div>
                        <div class="flip-card-back">
                            <div class="plan-logo">
                                <img src="{{card.imgUrl}}">
                            </div>
                            <div class="text-back-arr">
                                <p [innerHTML]="t" *ngFor="let t of card.textBack"></p>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="price-tag"
                        [ngClass]="card.price === 395 ? 'price-tag-best' : ''"
                        (click)="goTo('contratarAhora')">
                    Contratar ahora por ${{card.price}}
                </button>
            </div> -->
            <div class="card-container">
                <button class="price-tag"
                        (click)="goTo('contratarAhora')">
                        CONTRATAR AHORA POR $339
                </button>
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="plan-logo">
                                <img src="assets/images/ituranProteccion.png">
                            </div>
                            <p class="front-p"><strong>Servicio de localización y recuperación vehicular.</strong>
                                 Si llegaras a sufrir un robo total del vehículo, recuperamos el automóvil
                                 hasta en 48 horas*. Con un precio de <span class="green-price">$339</span> al mes.</p>


                          <div class="plan-tags">
                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_mobile.png"
                                alt="Icono Rastreador GPS">
                              <p>Rastreador GPS</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_car.png">
                              <p style="width:80px; text-align: right;">Asistencia vial 24/7</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_mobile_a.png"
                                alt="Icono Rastreador GPS">
                              <p style="width:80px; text-align: right;">Rastreo 24/7 desde la app</p>
                            </div>

                          </div>

                        </div>
                        <div class="flip-card-back">
                            <div class="plan-logo">
                                <img src="assets/images/ituranProteccion.png">
                            </div>
                            <div class="text-back-arr">
                                <p class="front-p"><strong>Ituran Protección</strong> es <strong>únicamente</strong> un servicio de localización y
                                    <strong>recuperación</strong>  vehicular.</p>
                                <p class="front-p">Si llegaras a sufrir un percance de robo total de tu auto, y por alguna razón no lo pudiéramos recuperar, te <strong>indemnizamos con el 10%</strong> del valor comercial del mismo. <br>(Hasta por $20,000).</p>
                                <p class="front-p">La tasa de recuperación del vehículo es del 80%.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-container">
                <button class="price-tag"
                        (click)="goTo('contratarAhora')">
                        CONTRATAR AHORA POR $349
                </button>
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="plan-logo">
                                <img src="assets/images/ituranConSeguro.png">
                            </div>
                            <p class="front-p">Si llegaras a sufrir un precance de robo total de tu auto, y por alguna razon
                                no lo pudiéramos recuperar, el seguro te lo paga gracias a la póliza emitida
                                por las aseguradoras <span class="chubb-icon"><img src="assets/icons/chubb_bw.png"></span>
                                y
                                <span class="hdi-icon">
                                    <img src="assets/icons/hdi_bw.png">
                               </span>
                                Con un precio de <span class="green-price">$349</span> al mes.
                           </p>

                          <div class="plan-tags">
                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_mobile.png"
                                alt="Icono Rastreador GPS">
                              <p>Rastreador GPS</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_car.png">
                              <p style="width:80px; text-align: right;">Asistencia vial 24/7</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_mobile_a.png"
                                alt="Icono Rastreador GPS">
                              <p style="width:80px; text-align: right;">Rastreo 24/7 desde la app</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_ok.png"
                                alt="Icono Rastreador GPS">
                              <p style="width:80px; text-align: right;">Póliza contra robo de auto</p>
                            </div>

                          </div>

                        </div>
                        <div class="flip-card-back">
                            <div class="plan-logo">
                                <img src="assets/images/ituranConSeguro.png">
                            </div>
                            <div class="text-back-arr">
                                <p class="front-p">Póliza contra robo total de automóvil emitida por las
                                    aseguradoras
                                    <span class="chubb-icon">
                                        <img src="assets/icons/chubb_bw.png">
                                    </span>
                                     y
                                     <span class="hdi-icon">
                                         <img src="assets/icons/hdi_bw.png">
                                    </span>
                                     seguros. Servicio de localización y recuperación vehicular. Si llegaras a sufrir un robo total
                                     del vehículo, recuperamos el automóvil <br>hasta en 48 horas*.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-container">
                <button class="price-tag price-tag-best"
                        (click)="goTo('contratarAhora')">
                    CONTRATAR AHORA POR $395
                </button>
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="plan-logo">
                                <img src="assets/images/ituranCSDT.png">
                            </div>
                            <p class="front-p">
                                <strong>Ituran con seguro + Daños a terceros </strong> te brinda
                                 todos los beneficios de <strong>Ituran con Seguro</strong> más responsabilidad
                                 civil para bienes y personas. Con un precio de <span class="blue-price">$395</span> al mes.
                            </p>

                            <div class="plan-tags">
                              <div class="dplan-t">
                                <img
                                  src="assets/icons/plans/icon_mobile.png"
                                  alt="Icono Rastreador GPS">
                                <p>Rastreador GPS</p>
                              </div>

                              <div class="dplan-divider"></div>

                              <div class="dplan-t">
                                <img
                                  src="assets/icons/plans/icon_car.png">
                                <p style="width:80px; text-align: right;">Asistencia vial 24/7</p>
                              </div>

                              <div class="dplan-divider"></div>

                              <div class="dplan-t">
                                <img
                                  src="assets/icons/plans/icon_mobile_a.png"
                                  alt="Icono Rastreador GPS">
                                <p style="width:80px; text-align: right;">Rastreo 24/7 desde la app</p>
                              </div>

                              <div class="dplan-divider"></div>

                              <div class="dplan-t">
                                <img
                                  src="assets/icons/plans/icon_ok.png"
                                  alt="Icono Rastreador GPS">
                                <p style="width:80px; text-align: right;">Póliza contra robo de auto</p>
                              </div>

                              <div class="dplan-divider"></div>

                              <div class="dplan-t">
                                <img
                                  src="assets/icons/plans/icon_accidente.png"
                                  alt="Icono Rastreador GPS">
                                <p>Daños a terceros</p>
                              </div>
                            </div>


                        </div>
                        <div class="flip-card-back">
                            <div class="plan-logo">
                                <img src="assets/images/ituranCSDT.png">
                            </div>
                            <div class="text-back-arr">
                                <p class="front-p"><strong>Ituran con Seguro + Daños a terceros</strong>
                                    la suma de responsabilidad civil para
                                    bienes y personas por $500, 000
                                    gracias a la póliza emitida por la
                                    aseguradora <span class="hdi-icon">
                                        <img src="assets/icons/hdi_bw.png">
                                   </span>
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-container">
              <a href="tel:8009119898" class="price-tag price-tag-best price-tag-phone">
                LLAMA AHORA PARA CONTRATAR
              </a>
                <div class="flip-card">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <div class="plan-logo">
                                <img src="assets/images/asset_18.png">
                            </div>
                            <p class="front-p">
                              <strong>Ituran con Seguro + Cobertura Amplia</strong>
                              ¡Protégete ante cualquier situación! El servicio más
                              completo de Ituran con Seguro, con una póliza emitida por la aseguradora
                              <span class="hdi-icon">
                                    <img src="assets/icons/hdi_bw.png">
                               </span>
                              El precio depende del modelo de tu auto.
                            </p>

                          <div class="plan-tags">
                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_mobile.png"
                                alt="Icono Rastreador GPS">
                              <p>Rastreador GPS</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_car.png">
                              <p style="width:80px; text-align: right;">Asistencia vial 24/7</p>
                            </div>


                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_mobile_a.png"
                                alt="Icono Rastreador GPS">
                              <p style="width:80px; text-align: right;">Rastreo 24/7 desde la app</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_ok.png"
                                alt="Icono Rastreador GPS">
                              <p style="width:80px; text-align: right;">Póliza contra robo de auto</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_accidente.png"
                                alt="Icono Rastreador GPS">
                              <p>Daños a terceros</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img width="35" height="auto"
                                   src="assets/icons/plans/icon_choque.png"
                                   alt="Icono Rastreador GPS">
                              <p>Daños materiales</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img
                                src="assets/icons/plans/icon_hand.png"
                                alt="Icono Rastreador GPS">
                              <p style="width:90px; text-align: right;">Gastos médicos ocupantes</p>
                            </div>

                            <div class="dplan-divider"></div>

                            <div class="dplan-t">
                              <img width="30" height="auto"
                                   src="assets/icons/plans/icon_hammer.png"
                                   alt="Icono Rastreador GPS">
                              <p>Asistencia jurídica</p>
                            </div>

                            <div class="dplan-divider"></div>

                          </div>



                        </div>
                        <div class="flip-card-back">
                            <div class="plan-logo">
                                <img src="assets/images/asset_18.png">
                            </div>
                            <div class="text-back-arr">
                                <p class="front-p"><strong>Ituran con Seguro + Cobertura Amplia</strong>
                                  Póliza emitida por

                                  <span class="hdi-icon">
                                    <img src="assets/icons/hdi_bw.png">
                                  </span>

                                  cubre una suma por daños a
                                  terceros de $2,000,000 y $200,000 por gastos médicos
                                  ocupantes. Cobertura por robo total y daños materiales
                                  por el costo de tu vehículo con un deducible de 10%.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
