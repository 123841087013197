<div class="container">
    <div class="progress-bar">
        <div class="line-bg-container">
            <div class="line-bg">
            </div>
        </div>
        <div class="steps">
            <div class="step">
                <div class="step-circle" [style.background-color]="getBackgroundColor(1)">
                    <!-- <img src="assets/icons/centro.png" alt=""> -->
                    <!-- <p>1</p> -->
                </div>
                <div class="step-text">
                    <p>Usuario</p>
                </div>
            </div>
            <div class="step">
                <div class="step-circle" [style.background-color]="getBackgroundColor(2)">
                    <!-- <img src="assets/icons/centro.png" alt=""> -->
                    <!-- <p>2</p> -->
                </div>
                <div class="step-text">
                    <p>Auto</p>
                </div>
            </div>
            <div class="step">
                <div class="step-circle" [style.background-color]="getBackgroundColor(3)">
                    <!-- <img src="assets/icons/centro.png" alt=""> -->
                    <!-- <p>3</p> -->
                </div>
                <div class="step-text">
                    <p>Servicio</p>
                </div>
            </div>
            <div class="step">
                <div class="step-circle" [style.background-color]="getBackgroundColor(4)">
                    <!-- <img src="assets/icons/centro.png" alt=""> -->
                    <!-- <p>4</p> -->
                </div>
                <div class="step-text">
                    <p>Confirma</p>
                </div>
            </div>
            <div class="step">
                <div class="step-circle" [style.background-color]="getBackgroundColor(5)">
                    <!-- <img src="assets/icons/centro.png" alt=""> -->
                    <!-- <p>5</p> -->
                </div>
                <div class="step-text">
                    <p>Paga</p>
                </div>
            </div>
            <div class="step">
                <div class="step-circle" [style.background-color]="getBackgroundColor(6)">
                    <!-- <img src="assets/icons/centro.png" alt=""> -->
                    <!-- <p>6</p> -->
                </div>
                <div class="step-text">
                    <p>Agenda</p>
                </div>
            </div>
        </div>
    </div>
</div>
