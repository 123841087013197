<div class="container-fluid final_footer">
    <div class="row">
      <div class="col-md-3 final_section">
        <img src="assets/icons/logo-white.svg" alt="">
      </div>
      <div class="col-md-3 final_section" style="text-align: left; padding-left: 50px;">
        <span><a href="#" style="color: white !important;">Inicio</a></span> <br><br>
        <span onclick="goTo('quees')">¿Qué es Ituran?</span> <br><br>
        <span onclick="goTo('incluye')">¿Qué incluye?</span> <br><br>
        <span class="load_desktop" onclick="goTo('pagas')">¿Cómo lo pagas? <br><br></span>
        <span><a [routerLink]="['/faqs']" style="color: white !important;">Preguntas frecuentes</a></span>
      </div>
      <div class="col-md-3 final_section" style="text-align: left; padding-left: 50px;">
        <a href="assets/info/T&C SERVICIOS ITURAN.pdf" target="_blank" style="color: white;">
          Términos y condiciones
        </a> <br><br>
        <a href="assets/info/AVISO DE PRIVACIDAD SERVICIOS ITURAN.pdf" target="_blank" style="color: white;">
          Aviso de privacidad
        </a><br><br>
        <div class="row desktop_view">
          <div class="col-md-2">
            <a href="https://www.facebook.com/IturanConSeguroMx" target="_blank"><img src="assets/icons/facebook.svg" alt=""></a>
          </div>
          <div class="col-md-2">
            <a href="https://www.youtube.com/channel/UC_4fQSu1Im5ZtgX9lhqvc8w" target="_blank"><img src="assets/icons/youtube.svg" alt=""></a>
          </div>
          <div class="col-md-2">
            <a href="https://instagram.com/ituranconseguromx?igshid=1fcvcamnn1z9y" target="_blank"><img src="assets/icons/instagram.svg" alt=""></a>
          </div>
        </div>
      </div>
      <div class="row mobile_view" style="display: none;">
        <a href="https://www.facebook.com/IturanConSeguroMx" target="_blank"><img src="assets/icons/facebook.svg" alt=""></a>
        <a href="https://www.youtube.com/channel/UC_4fQSu1Im5ZtgX9lhqvc8w" target="_blank"><img src="assets/icons/youtube.svg" alt=""></a>
        <a href="https://instagram.com/ituranconseguromx?igshid=1fcvcamnn1z9y" target="_blank"><img src="assets/icons/instagram.svg" alt=""></a>
      </div>
      <div class="col-md-3 final_section" style="text-align: left; padding-left: 50px;">
        Formas de pago <br>
        <img src="assets/icons/pago.png" alt=""><br><br>
        Aliados <br>
        <img src="assets/icons/seguros_white.png" width="160" alt="" style="margin-left: -15px;">
        <!--img src="assets/icons/chubb_bw.png" width="160" alt="" style="margin-left: -8px;"> <img src="assets/icons/hdi_bw.png" width="50" style="margin-left: 20px;" alt=""><br--><br>
        Ituran en el mundo <br>
        <div class="row world">
          <div class="col-md-2" style="padding: 10px; padding-top: 5px; margin-left: 5px;">
            <a href="https://www.ituran.com.br/" target="_blank"><img src="assets/icons/brasil.svg" class="w-100" alt=""></a>
          </div>
          <div class="col-md-2" style="padding: 10px; padding-top: 5px;">
            <a href="https://www.ituranusa.com/" target="_blank"><img src="assets/icons/eu.svg" class="w-100" alt=""></a>
          </div>
          <div class="col-md-2" style="padding: 10px; padding-top: 5px;">
            <a href="https://www.ituran.com.ar/" target="_blank"><img src="assets/icons/argentina.svg" class="w-100" alt=""></a>
          </div>
          <div class="col-md-2" style="padding: 10px; padding-top: 5px;">
            <a href="https://ituran.com.co/" target="_blank"><img src="assets/icons/colombia.svg" class="w-100" alt=""></a>
          </div>
          <div class="col-md-2" style="padding: 10px; padding-top: 5px;">
            <a href="https://www.ituran.com.ec/" target="_blank"><img src="assets/icons/ecuador.svg" class="w-100" alt=""></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal -->
  <div class="modal fade" id="modalAP" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal_body" role="document">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 modal_info" style="height: auto !important;">
              <button class="cerrarModal load_mobile" data-dismiss="modal" aria-label="Close">x</button>
              <h3>AVISO DE PRIVACIDAD</h3>
              <button class="cerrarModal load_desktop" data-dismiss="modal" aria-label="Close" style="margin-left: 85% !important; margin-top: -70px;">x</button>
              <div class="linea"></div>
              <h4 class="text-justify">
                AVISO DE PRIVACIDAD PARA CLIENTES SERVICIOS UBIKO® EN TERMINOS DE LA LEY FEDERAL DE PROTECCIÓN DE DATOS 
                PERSONALES EN POSESIÓN DE LOS PARTICULARES Y SU REGLAMENTO (EN LO SUCESIVO LA “LEY”)
              </h4>
              <p class="txt_modal">
                DENTIDAD Y DOMICILIO DEL RESPONSABLE DE SUS DATOS PERSONALES: ROAD TRACK MÉXICO, S.A. DE C.V., en su calidad de Responsable del tratamiento de sus datos personales y/o datos personales sensibles (en lo sucesivo se le referirá como “Road Track”), con domicilio en Avenida del Taller No. 36 Col. Tránsito, Alcaldía Cuauhtémoc, C.P. 06820, Ciudad de México.
                <br><br>
                <span class="strong_blue">DATOS PERSONALES y/o DATOS PERSONALES SENSIBLES:</span> <br> 
                Para efectos de la LEY se entiende por DATOS PERSONALES cualquier información concerniente al TITULAR que firma el presente Aviso, y/o DATOS PERSONALES SENSIBLES, y/o aquellos datos personales que afecten a la esfera más íntima de su titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y morales, afiliación sindical, opiniones políticas o preferencia sexual.
                <br><br>
                <span class="strong_blue">FINALIDAD DEL TRATAMIENTO DE SUS DATOS PERSONALES:</span> <br> 
                “Road Track” podrá recabar de Usted los Datos Personales que sean necesarios para el cumplimiento de los Servicios contratados; en consecuencia, el Tratamiento de los Datos Personales será desempeñado mediante su obtención, uso, divulgación o almacenamiento de datos personales, por cualquier medio. Para fines del presente Aviso el uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o disposición de Datos Personales, en general para los demás fines y usos establecidos en la LEY y reglamentos aplicables.
                <br><br>
                <span class="strong_blue">DATOS PERSONALES QUE SERÁN TRATADOS POR “Road Track”:</span> <br> 
                Para las finalidades antes mencionadas, “Road Track” podrá obtener de Usted los Datos Personales que requiera y que de forma enunciativa y no limitativa, se mencionan a continuación: (i) Nombre completo; (ii) Domicilio particular; (iii) Datos de información Personal (tales como edad, género, estado civil, nacionalidad), (iv) Datos de información Gubernamental (Registro Federal de Contribuyentes RFC, Clave única de Registro de Población CURP); (v) Correo electrónico; (vi)Teléfono laboral y particular, (vii) Teléfono celular; (viii)Información Financiera; (ix) Datos de Terceros relacionados con Usted, (x) Datos de contactos de emergencia; (xi)Cualquier otro Dato Personal y/o dato personal sensible relacionado con la prestación de los SERVICIOS UBIKO®.
                <br><br>
                <span class="strong_blue">PLAZOS PARA EL TRATAMIENTO DE LOS DATOS PERSONALES:</span> <br> 
                La temporalidad del manejo y tratamiento de los Datos Personales y/o sensibles, dependerá directamente de la relación jurídica que se tenga celebrada con “Road Track” para la prestación de los servicios contratados así como de las obligaciones exigidas por la legislación vigente y las autoridades competentes. En todos los casos la información será conservada por el plazo necesario para el cumplimiento de las finalidades que justificaron su tratamiento. Así como para dar cumplimiento a las responsabilidades nacidas como consecuencia del propio tratamiento.
                <br><br>
                <span class="strong_blue">PROTECCIÓN DE DATOS PERSONALES:</span> <br> 
                “Road Track” cumple los principios rectores de protección de Datos Personales de Licitud, Consentimiento, Información, Finalidad, Proporcionalidad, Lealtad, Calidad y Responsabilidad, establecidos por la LEY, así mismo adopta las medidas necesarias para su aplicación.”Road Track”, adoptará los medios y mecanismos necesarios y suficientes para procurar que el tratamiento de los Datos Personales se en apego a la legislación aplicable; privilegiando en todo momento la protección de sus intereses y privacidad, mediante su debido resguardo y protección a efecto de que se impida su pérdida, mal uso, alteración, acceso no autorizado y/o robo. Por tanto, sólo tendrán acceso a la información aquellas personas autorizadas por “Road Track” quienes han asumido el compromiso de mantener la información bajo un estricto orden de confidencialidad y seguridad.
                <br><br>
                <span class="strong_blue">DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN (ARCO) DEL TRATAMIENTO DE DATOS PERSONALES:</span> <br> 
                De conformidad con LEY Usted tiene derecho a ejercer en cualquier momento sus derechos ARCO para acceder a sus Datos Personales, rectificarlos cuando sean inexactos o incompletos en cuyo caso será facultad de “Road Track” solicitar aquellas documentación que acredite las correcciones o complementos solicitados, oponerse a su tratamiento en cuyo caso “Road Track” no se encontrará obligada a suspender el tratamiento de los datos en los supuestos contemplados por la LEY, cancelar el tratamiento de sus Datos Personales, sin embargo “Road Track” no se encuentra obligada a cancelar aquellos datos contenidos en los supuestos establecidos por la LEY y revocar el consentimiento que para tal fin haya otorgado a través de los medios y procedimientos implementados y en observancia a la LEY. Para conocer los medios para ejercer sus derechos ARCO, así como los requisitos y plazos, se puede poner en contacto en la siguiente dirección electrónica adelaida.ramirez@road-track.com.
                <br><br>
                Cualquier solicitud deberá indicar su nombre, domicilio completo así como el la determinación del medio para darle respuesta, acompañado de una copia de su identificación oficial (pasaporte, credencial de elector o licencia de conducir), deberá contemplar la descripción clara y precisa de su requerimiento. De presentarse de la forma indicada la solicitud, “Road Track”, dará respuesta sobre la misma a través del medio mediante el cual haya sido indicado.
                <br><br>
                El ejercicio de los derechos antes mencionados, podrá ser restringido por “Road Track” de conformidad con la LEY en los siguientes supuestos: (i) disposiciones de orden público, seguridad o salud pública, (ii) para la protección de derechos de terceras personas y (iii) por resolución de autoridad competente, (iv) cuando el solicitante no acredite su personalidad, cuando en su base de datos, (v) no se encuentren los datos personales del solicitante.
                <br><br>
                <span class="strong_blue">TRANSFERENCIAS DE SUS DATOS PERSONALES:</span> <br> 
                Asimismo, le informamos que sus datos personales pueden ser transferidos y tratados dentro y fuera del país, por personas distintas a ”Road Track”, en términos de lo previsto por el Artículo 36 de la LEY. En ese sentido, su información puede ser compartida con; (i) otros empleados, contratistas, prestadores de servicio y asesores de ”Road Track”; (ii)agentes e instituciones privadas; (iii) terceros interesados en hacer negocios, otorgar financiamientos, comprar acciones o activos de “Road Track” o sus accionistas o filiales en México o alrededor del mundo o fusionarse con “Road Track”; (iv) aliados comerciales de “Road Track”; (v) autoridades en México o en el extranjero; y (vi) a cualquier otra persona autorizada por la LEY; asegurando que en la o las transferencias que de sus datos personales puedan efectuarse se estará en estricta observancia a los principios rectores en la materia.
                <br><br>
                <span class="strong_blue">CAMBIOS AL AVISO DE PRIVACIDAD:</span>“Road Track” se reserva el derecho a cambiar los términos y condiciones de este Aviso de Privacidad, notificándolo mediante medios electrónicos y/o físicos y/o un anuncio en nuestra página de Internet.
                <br><br>
                <span class="strong_blue">LIMITES EN EL USO O DIVULGACIÓN DE DATOS:</span> <br> 
                Si “Road Track” necesitara tratar los Datos Personales para un fin distinto al establecido presente en aviso de privacidad, requerirá nuevamente su consentimiento.
                <br><br>
                <span class="strong_blue">LEGISLACIÓN Y JURISDICCIÓN:</span> <br> 
                Este Aviso de Privacidad se rige por la LEY y las demás leyes y reglamentos de los Estados Unidos Mexicanos. La aceptación de este Aviso de Privacidad implica una aceptación por escrito de los términos del mismo y su sometimiento expreso a los tribunales de la Ciudad de México, México, para cualquier controversia o reclamación derivada de este Aviso de Privacidad.
              </p>
            </div>
          </div>
        </div>
    </div>
  </div>