<div id="what-next" class="container">
    <div [style.background-image]="'url(assets/images/summary_bg.webp'" class="static-component">
        <div class="what-next-1">
            <h4>Una vez que compraste, ¿qué sigue?</h4>
        </div>
        <div class="what-next-2">
            <div class="card">
                <div class="img-container">
                    <img src="assets/images/whatnext_map.png" alt="">
                </div>
                <p>Agenda tu cita dónde instalaremos tu rastreador GPS en uno de nuestros talleres que elijas.</p>
            </div>
            <div class="card">
                <div class="img-container">
                    <img src="assets/images/whatnext_correo.png" alt="">
                </div>
                <p>Cuando tu póliza llega a tu correo electrónico, tu auto ya está asegurado.</p>
            </div>
            <div class="card">
                <div class="img-container">
                    <img src="assets/images/whatnext_app.png" alt="">
                </div>
                <p>Descarga la aplicación Ituran  para que sepas dónde está tu auto las  24 hrs. del día. Paga al corriente para estar siempre protegido.</p>
            </div>
        </div>
    </div>
</div>
