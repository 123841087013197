import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-how-to-pay',
  templateUrl: './how-to-pay.component.html',
  styleUrls: ['./how-to-pay.component.css'],
  encapsulation: ViewEncapsulation.ShadowDom 
})
export class HowToPayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
