<div *ngIf="nmCotizacion === 'RASTREADOR ITURAN GPS/GPRS'" class="flip-card-front">
    <div class="plan-logo">
        <img src="assets/images/ituranProteccion.png">
    </div>
    <p class="front-p"><strong>Servicio de localización y recuperación vehicular.</strong> 
         Si llegaras a sufrir un robo total del vehículo, recuperamos el automóvil
         hasta en 48 horas*. Con un <br> precio de <span class="green-price">$339</span> al mes.</p>
    <div class="plan-tags">
        <div class="plan-tag">
            <img src="assets/images/tag_gps.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_asistencia.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_app.png" alt="">
        </div>
    </div>
</div>

<div *ngIf="nmCotizacion === 'ITURAN CON SEGUROS MEXICO'" class="flip-card-front">
    <div class="plan-logo">
        <img src="assets/images/ituranConSeguro.png">
    </div>
    <p class="front-p">Si llegaras a sufrir un precance de robo total de tu auto, y por alguna razon
        no lo pudiéramos recuperar, el seguro te lo paga gracias a la póliza emitida 
        por las aseguradoras <span class="chubb-icon"><img src="assets/icons/chubb_bw.png"></span>  
        y  
        <span class="hdi-icon">
            <img src="assets/icons/hdi_bw.png">
       </span>  
        Con un precio de <span class="green-price">$349</span> al mes.
   </p>
    <div class="plan-tags">
        <div class="plan-tag">
            <img src="assets/images/tag_gps.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_asistencia.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_app.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_polizaUnica.png" alt="">
        </div>
    </div>
</div>

<div *ngIf="nmCotizacion === 'ITURAN CON SEGURO + RC'" class="flip-card-front">
    <div class="plan-logo">
        <img src="assets/images/ituranCSDT.png">
    </div>
    <p class="front-p">
        <strong>Ituran con seguro + Daños a terceros </strong> te brinda 
         todos los beneficios de <strong>Ituran con Seguro</strong> más responsabilidad
         civil para bienes y personas. Con un precio de <span class="blue-price">$395</span> al mes.
    </p>
    <div class="plan-tags">
        <div class="plan-tag">
            <img src="assets/images/tag_gps.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_asistencia.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_app.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_polizaUnica.png" alt="">
        </div>
        <div class="plan-tag">
            <img src="assets/images/tag_danios.png" alt="">
        </div>
    </div>
</div>