<div class="container">
    <div [style.background-image]="'url(assets/images/howtopay_bg.webp'" class="static-component">
        <div class="how-to-pay-1">
            <h4>Proceso de compra</h4>
        </div>
        <div class="how-to-pay-2">
            <div class="compra-group">
                <img src="assets/images/compra_telefono.png" alt="">
                <p>Puedes llamarnos al 800-911-9898 y contratar.</p>
            </div>
            <div class="compra-group">
                <img src="assets/images/compra_monitor.png" alt="">
                <p>Compra en línea llenando el formulario que encontrarás abajo.</p>
                <span>(Ten a la mano tu tarjeta de circulación y método de pago)</span>
            </div>
            <div class="compra-group">
                <img src="assets/images/compra_whatsapp.png" alt="">
                <p>Para una atención personalizada da clic en el botón de WhatsApp</p>
            </div>
        </div>
    </div>
</div>
