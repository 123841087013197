<div class="iw-container">
    <div class="static-component" [style.background-image]="'url(assets/images/summary_bg.webp'">
        <div class="summary-1">
            <p><span>Ituran</span> es la alternativa ideal para aquellas personas que necesitan recuperar su utomóvil en el menor tiempo posible en caso de robo total.</p>
            <P>Y de no ser así, contar un seguro que respalde la indemnización del vehículo, además de tener un servicio de rastreo y localización del vehículo desde su celular:</P>
        </div>
        <div class="summary-2">
            <div class="summary-group">
                <img src="assets/images/summary_money.png" alt="">
                <p>A un precio accesible</p>
            </div>
            <div class="summary-group">
                <img src="assets/images/summaryCalendar.png" alt="">
                <p>Sin plazo forzoso</p>
            </div>
            <div class="summary-group">
                <img src="assets/images/summary_pay.png" alt="">
                <p>A un precio accesible</p>
            </div>
        </div>
        <div class="summary-3">
            <button (click)="goTo('contratarAhora')">CONTRATAR AHORA ITURAN</button>
        </div>
    </div>
</div>
