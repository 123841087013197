
<!-- <app-header></app-header> -->
<app-welcome-slider></app-welcome-slider>
<!--<app-welcome-banner></app-welcome-banner>-->
<app-go-to></app-go-to>
<app-plans id="plans"></app-plans>
<app-general></app-general>
<app-app-add></app-app-add>
<app-slider></app-slider>
<app-how-to-pay id="howToPay"></app-how-to-pay>
<app-subscribe-now id="contratarAhora"></app-subscribe-now>
<app-what-next></app-what-next>
<app-yt-video></app-yt-video>
<app-faq id="faq"></app-faq>
<app-summary id="summary"></app-summary>
<!-- <app-footer></app-footer> -->
