export class Vehiculo{
    public placa: string = '';
    public vin: string = '';
    public contacto1: string = '';
    public telefonoc1: string = '';
    public parentezco1: string = '';
    public contacto2: string = '';
    public telefonoc2: string = '';
    public parentezco2: string = '';
    constructor(){}
  }